import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import MetaTags from "react-meta-tags";
import { TabContent, TabPane, Nav, NavItem, NavLink, Label } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";

import { HeaderWithButton } from "components/HeaderWithButton";
import { CustomButton } from "components/CustomButton";
import { APP_ROUTES } from "helpers/routeHelpers";
import { TextInput } from "components/Input";
import { SuccessModalCustom } from "components/SuccessModalCustom";
import { METATAGS } from "../../constants";
import { getFirstStepFee, updateFirstStepFee } from "api/api.service";

import EditIcon from "../../assets/images/aiAPaiT/Settings/edit-icon.svg";

const Listing = () => {
    const history = useHistory();
    let userData = useSelector((store) => store?.userAuth?.user);
    let userType = userData?.type === "ADMIN" || userData?.type === "SUPER_ADMIN";
    userData["userCountries"] = userData?.userCountry?.map((el) => el?.country?.name);

    const [activeTab, setActiveTab] = useState("1");
    const [isUpdateFeeSuccessModalActive, setIsUpdateFeeSuccessModalActive] = useState(false);
    const [isEditFee, setIsEditFee] = useState(false);
    const [isFeesLoading, setIsFeesLoading] = useState(false);
    const [firstStepFees, setFirstStepFees] = useState("");

    // tab click handling
    const toggle = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    // profile button click handling
    const handleProfileButtonClick = () => {
        history.push(APP_ROUTES.SETTINGS_DETAILS);
    };

    // change password button click handling
    const handleChangePassButtonClick = () => {
        history.push(APP_ROUTES.SETTINGS_CHANGE_PASS);
    };

    // formik validation for input fields
    const formik = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            stepOneFee: firstStepFees
        },
        validationSchema: Yup.object({
            stepOneFee: Yup.number()
                .integer()
                .required("Please Enter Your Step 1 Fee")
                .min(1, "Step 1 Fee shouldn't be less than $1")
                .max(1000, "Step 1 Fee shouldn't be greater than $1000")
        }),

        onSubmit: (values) => {
            handleSubmit(values);
        }
    });

    // form submission handling
    const handleSubmit = async (values) => {
        try {
            setIsFeesLoading(true);
            await updateFirstStepFee({ filingFees: values?.stepOneFee });
            setIsEditFee(false);
            setIsUpdateFeeSuccessModalActive(true);
        } finally {
            setIsFeesLoading(false);
        }
    };

    // success modal for step fee update close handling
    const updateFeesSuccessModalCloseHandler = () => {
        setIsUpdateFeeSuccessModalActive(false);
        history.push(APP_ROUTES.SETTINGS);
    };

    // update step fee on tab click handling
    useEffect(async () => {
        if (activeTab === "2") {
            try {
                let res = await getFirstStepFee();
                setFirstStepFees(res?.filingAmount);
            } finally {
            }
        }
    }, [activeTab]);

    return (
        <>
            <MetaTags>
                <title>{METATAGS.SETTINGS}</title>
            </MetaTags>
            <div className="settings-con p-3">
                <Nav tabs>
                    <NavItem>
                        <NavLink
                            className={activeTab === "1" ? "active" : ""}
                            onClick={(e) => toggle("1")}
                        >
                            Profile Settings
                        </NavLink>
                    </NavItem>
                    {userType ? (
                        <NavItem>
                            <NavLink
                                className={activeTab === "2" ? "active" : ""}
                                onClick={(e) => toggle("2")}
                            >
                                Payment Settings
                            </NavLink>
                        </NavItem>
                    ) : null}
                </Nav>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                        <div className="setting-profile-setting-tab">
                            <HeaderWithButton
                                isSettingDetails={true}
                                isButton={false}
                                className="p-0"
                                cardClassName="mb-0 shadow-none pt-2"
                                data={userData}
                            />
                            <div className="btns-con bg-white p-3">
                                <CustomButton
                                    title="Edit Profile"
                                    className="btn-primary cd-header-btn shadow-none setting-edit-profile-btn me-3"
                                    color="#704DE7"
                                    onClick={handleProfileButtonClick}
                                />
                                <CustomButton
                                    title="Change Password"
                                    className="cd-header-btn shadow-none setting-changepass-profile-btn "
                                    color="#704DE7"
                                    outline
                                    onClick={handleChangePassButtonClick}
                                />
                            </div>
                        </div>
                    </TabPane>
                    {userType ? (
                        <TabPane tabId="2">
                            <form className="" onSubmit={formik.handleSubmit}>
                                <div className="setting-profile-setting-tab setting-profile-payment">
                                    <div className="bg-white p-3 pt-4">
                                        <Label>Step 1 Fee (USD)</Label>
                                        <div className="mb-3">
                                            <TextInput
                                                placeholder="First Step Fees (USD)"
                                                name={"stepOneFee"}
                                                type="number"
                                                className="hide-icon"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.stepOneFee}
                                                autoFocus
                                                iconFile={EditIcon}
                                                appendIcon={true}
                                                disabled={!isEditFee}
                                                iconClickHandler={() => setIsEditFee(!isEditFee)}
                                                preAppendIconFile={"$"}
                                            />
                                            {formik.touched.stepOneFee &&
                                            formik.errors.stepOneFee ? (
                                                <span className="error">
                                                    {formik.errors.stepOneFee}
                                                </span>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="btns-con bg-white p-3">
                                        <CustomButton
                                            title="Save Changes"
                                            className="btn-primary cd-header-btn shadow-none setting-edit-profile-btn me-3"
                                            color="#704DE7"
                                            type={"submit"}
                                            loading={isFeesLoading}
                                        />
                                    </div>
                                </div>
                            </form>
                        </TabPane>
                    ) : null}
                </TabContent>
            </div>
            <SuccessModalCustom
                btnTitle="Done"
                mainContent="Step 1 fee has been updated successfully"
                modalActivator={isUpdateFeeSuccessModalActive}
                modalCloseHandler={updateFeesSuccessModalCloseHandler}
            />
        </>
    );
};

export default Listing;
