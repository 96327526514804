import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { CustomModal } from "components/CustomModal";
import { CodeInput } from "components/CodeInput";
import { CustomButton } from "components/CustomButton";

const OTP_LENGTH = 4;
const RESEND_INTERVAL = 60; // 60 seconds

const VerifyOTPModal = ({
    modalActivator = false,
    submitDataHandler = () => {},
    OTPResetHandler = () => {},
    modalCloseHandler = () => {},
    isResendCode,
    setIsResendCode
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [OTP, setOTP] = useState("");

    const [timer, setTimer] = useState(RESEND_INTERVAL);

    // Start timer when modal opens, reset when modal closes
    useEffect(() => {
        let interval;
        if (modalActivator) {
            setTimer(RESEND_INTERVAL); // Reset timer to 60 seconds when modal opens
            interval = setInterval(() => {
                setTimer((prevTimer) => {
                    if (prevTimer <= 0) {
                        clearInterval(interval);
                        setIsResendCode(false); // Allow user to resend OTP
                        return RESEND_INTERVAL;
                    }
                    return prevTimer - 1;
                });
            }, 1000);
        }

        return () => {
            clearInterval(interval); // Clear timer when modal is closed
        };
    }, [modalActivator, isResendCode]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        submitDataHandler(OTP);
        setOTP("");
    };

    const handleResendCode = async (e) => {
        e.preventDefault();
        OTPResetHandler();
    };
    return (
        <>
            <CustomModal
                isOpen={modalActivator}
                onClose={modalCloseHandler}
                modalCloseIconClass="close-modal-icon pb-0"
                className="add-department-custom-modal reset-pass-modal-con"
                modalHeaderClass="header-padding"
                data={
                    <div className="cs-auth-wrapper ">
                        <div className="bv-forget-wrap my-5">
                            <p className="login-type ">One Time Password</p>
                            <form className="mt-3" onSubmit={handleSubmit}>
                                {/* <div className="bg-theme-primary-light text-white rounded-1 text-center p-2 mb-4 font-size-12 opacity-50">
                      {` Please enter the 4-digit code sent to ${email}`}
                  </div> */}
                                <CodeInput
                                    className="my-4 text-center"
                                    onChange={(e) => setOTP(e)}
                                />
                                <CustomButton
                                    disabled={OTP.length != OTP_LENGTH}
                                    loading={isLoading}
                                    // onClick={handleSubmit}
                                    color="primary"
                                    type={"submit"}
                                    title="Verify"
                                    className=" mt-2"
                                />
                            </form>
                            <div
                                className={`d-flex mt-4 align-items-center justify-content-center ${
                                    !isResendCode ? "" : "disable-resent"
                                }`}
                                onClick={!isResendCode ? handleResendCode : null}
                            >
                                <a className="resend-otp-typo">
                                    {isResendCode ? `Resend OTP in (${timer} sec)` : "Resend Now"}
                                </a>
                            </div>
                        </div>
                    </div>
                }
            />
        </>
    );
};

VerifyOTPModal.propTypes = {
    modalActivator: PropTypes.bool,
    modalCloseHandler: PropTypes.func,
    OTPResetHandler: PropTypes.func,
    modalData: PropTypes.string,
    submitDataHandler: PropTypes.func,
    isResetOTPLoading: PropTypes.bool
};

export default VerifyOTPModal;
