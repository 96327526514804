import React from "react";
import PropTypes from "prop-types";
import { DebounceInput } from "react-debounce-input";
import { Input } from "reactstrap";
import SearchIcon from "../../assets/images/aiAPaiT/search-icon.svg";
const SearchBox = (props) => {
    const {
        className = "",
        iconClass = "",
        placeholder = "Search...",
        value = "",
        onChange = () => {},
        disable = false
    } = props;
    return (
        //remove form
        <div className={`app-search bv-search-box ${className}`}>
            <div className="position-relative">
                <DebounceInput
                    element={Input}
                    debounceTimeout={1000}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    className="form-control"
                    disabled={disable}
                    type="text"
                />
                <img src={SearchIcon} alt="search icon" />
                {/* <span className={`bx bx-search-alt ${iconClass}`} /> */}
            </div>
        </div>
    );
};

SearchBox.propTypes = {
    className: PropTypes.string,
    iconClass: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func,
    disable: PropTypes.bool
};

export default SearchBox;
